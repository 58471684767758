import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND;
axios.defaults.withCredentials = true

const doGet = (endpoint) => {
    let response = axios
        .get(API_URL + endpoint)
    return doResponse(response)
}

const doPost = (endpoint, params) => {
    let response = axios
        .post(API_URL + endpoint, params)
    return doResponse(response)
}

const doResponse = (res) => {
    return res.then(r => {
        return r.data
    }).catch(r => {
        if (r.response.status === 401) {
            localStorage.removeItem("user");
            window.location.replace("/login");
        } else if (r.response.status === 403) {
            return { "errors": ["Permission denied"] }
        } else {
            return r.response.data
        }
    })
}

const RequestService = {
    doGet,
    doPost,
}

export default RequestService;
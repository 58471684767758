import { useState, useEffect } from 'react';
import RequestService from '../services/RequestService'

export default function Findings() {
    const [entries, setEntries] = useState([])
    const [keywords, setKeywords] = useState([])

    function highlight(text) {
        var elements = document.getElementsByTagName("pre");
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            var innerHTML = element.innerHTML;
            var index = innerHTML.indexOf(text);
            if (index >= 0) {
                innerHTML = innerHTML.substring(0, index) + "<span class='bg-yellow-200'>" + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
                element.innerHTML = innerHTML;
            }
        }
    }

    useEffect(() => {
        keywords.forEach(k => {
            highlight(k)
        })
    }, [keywords])

    useEffect(() => {
        RequestService.doGet("intel/").then(data => {
            if (data) {
                setEntries(data.e)
                setKeywords(data.k)
            }
        })
    }, [])
    return (
        <>
            {entries.map((entry) => (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
                    <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start gap-x-3">
                            <h3 className="text-base font-semibold leading-7 text-gray-900">{entry.category}</h3>
                            <p
                                className='rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20'
                            >
                                {entry.keyword}
                            </p>
                        </div>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Found on {entry.date}</p>
                    </div>

                    <div className="border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-10 sm:mt-0">
                                    <pre>
                                        {entry.details}
                                    </pre>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            ))}
        </>
    )
}
import { useEffect } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        AuthService.logout();
        navigate('/login')
    })

    return (
        <></>
    )
}
import { Navigate } from "react-router-dom";
import Store from "./Store";

const Protected = ({ children }) => {
  let user = Store.getCurrentUser();
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default Protected;

import Store from '../services/Store';
import ApexCharts from 'apexcharts'
import RequestService from '../services/RequestService'
import { useState, useEffect } from 'react';

export default function Overview() {
    const [entries, setEntries] = useState([])
    const [total, setTotal] = useState(0)
    const [keywords, setKeywords] = useState([])

    function highlight(text) {
        var elements = document.getElementsByTagName("pre");
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            var innerHTML = element.innerHTML;
            var index = innerHTML.indexOf(text);
            if (index >= 0) {
                innerHTML = innerHTML.substring(0, index) + "<span class='bg-yellow-200'>" + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
                element.innerHTML = innerHTML;
            }
        }
    }

    useEffect(() => {
        keywords.forEach(k => {
            highlight(k)
        })
    }, [keywords])

    useEffect(() => {
        RequestService.doGet("intel/").then(data => {
            if (data) {
                setEntries(data.e)
                setTotal(data.t)
                setKeywords(data.k)
                var options = {
                    chart: {
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                        height: '300px'
                    },
                    series: [{
                        name: 'hits',
                        data: data.a
                    }],
                    xaxis: {
                        type: "datetime",
                    },
                    yaxis: {
                        min: 0,
                    },
                    tooltip: {
                        x: {
                            format: "MMM yyyy"
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#efefef',
                    },
                    stroke: {
                        width: 1,
                    }

                }
                var elem = document.querySelector("#chart");
                elem.innerHTML = ''
                var chart = new ApexCharts(elem, options);
                chart.render();

                let pieValues = {};

                data.e.forEach(i => {
                    if (Object.keys(pieValues).indexOf(i.category) > -1) {
                        pieValues[i.category] += 1
                    } else {
                        pieValues[i.category] = 1
                    }
                })
                var options2 = {
                    chart: {
                        type: 'pie',
                        toolbar: {
                            show: false,
                        },
                        height: '300px'
                    },
                    series: Object.values(pieValues),
                    labels: Object.keys(pieValues),
                    dataLabels: {
                        enabled: true,
                        dropShadow: {
                            enabled: false
                        }
                    }

                }
                var elem2 = document.querySelector("#chart2");
                elem2.innerHTML = ''
                var chart2 = new ApexCharts(elem2, options2);
                chart2.render();
            }
        })
    }, [])
    return (
        <>
            <div className="overflow-hidden rounded-lg bg-white shadow">
                <h2 className="sr-only" id="overview-title">
                    Overview
                </h2>
                <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                            <div className="flex-shrink-0">
                                <img className="mx-auto h-20 w-20 rounded-full" src={'https://ui-avatars.com/api/?background=666&color=fff&name=' + Store.getCurrentUser().organization} alt="" />
                            </div>
                            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                                <p className="text-xl font-bold text-gray-900 sm:text-2xl">{Store.getCurrentUser().organization}</p>
                                <p className="text-sm font-medium text-gray-600">{Store.getCurrentUser().user}</p>
                            </div>
                        </div>
                        <div className="mt-5 flex justify-center sm:mt-0 grid grid-cols-1">
                            <p className='text-red-400 text-6xl text-center'>{total}</p>
                            <p className={'text-gray-500 text-2xl text-center'}>Finding{total > 1 ? 's' : ''}</p>
                        </div>
                    </div>
                </div>


            </div>
            <div className='grid grid-cols-12 gap-4'>
                <div className="col-span-8 mt-4  overflow-hidden rounded-lg bg-white shadow">
                    <div className="border-b border-gray-100 bg-white p-4 sm:px-6">
                        <h3 className="font-semibold text-sm text-gray-700">Findings Timeline</h3>
                    </div>
                    <div className='px-4 py-8' id='chart'></div>
                </div>
                <div className="col-span-4 mt-4  overflow-hidden rounded-lg bg-white shadow">
                    <div className="border-b border-gray-100 bg-white p-4 sm:px-6">
                        <h3 className="font-semibold text-sm text-gray-700">Findings Category</h3>
                    </div>
                    <div className='px-4 py-8' id='chart2'></div>
                </div>

            </div>
            <div className='mt-4 overflow-hidden rounded-lg bg-white shadow'>
                <div className="border-b border-gray-100 bg-white p-4 sm:px-6">
                    <h3 className="font-semibold text-sm text-gray-700">Latest Findings</h3>
                </div>

                <ul className="px-8 py-4 divide-y divide-gray-100">
                    {entries.slice(0, 10).map((entry) => (
                        <li className="flex items-center justify-between gap-x-6 py-5">
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{entry.category}</p>
                                    <p
                                        className='rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20'
                                    >
                                        {entry.keyword}
                                    </p>

                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                    <p className="whitespace-nowrap">
                                        <time dateTime={entry.date}>{entry.date}</time>
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                                <p className='text-sm text-gray-400'>
                                    <pre>
                                        {entry.details}
                                    </pre>
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}
import axios from "axios";
import Store from "./Store";

const API_URL = process.env.REACT_APP_BACKEND;
axios.defaults.withCredentials = true

const login = async (email, password) => {
    const response = await axios
        .post(API_URL + "login/", {
            email,
            password,
        }).catch(function (error) {
            return error.response;
        });
    if (response.status === 200) {
        Store.setCurrentUser({ "email": response.data.email, "organization": response.data.organization })
    }
    return response.data;
};

const logout = async () => {
    localStorage.removeItem("user");
    const response = await axios.get(API_URL + "logout/");
    return response.data;
};

const AuthService = {
    login,
    logout,
}

export default AuthService;
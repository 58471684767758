const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setCurrentUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user))
}

const clear = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("organization");
}


const Store = {
    getCurrentUser,
    setCurrentUser,
    clear,
}

export default Store;
import { Routes, Route } from 'react-router-dom';
import Overview from './views/Overview.jsx';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './views/Login.jsx';
import Protected from './services/Protected.js'
import Findings from './views/Findings.jsx';
import Logout from './views/Logout.jsx';
import Page404 from './views/404.jsx';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Protected><DashboardLayout><Overview /></DashboardLayout></Protected>} />
      <Route path="/login" element={<Login />} />
      <Route path="/intel" element={<Protected><DashboardLayout><Findings /></DashboardLayout></Protected>} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Page404 />} />
    </Routes >
  );
}

export default App;
